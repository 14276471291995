// Generated from Aql.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { AqlListener } from "./AqlListener";

export class AqlParser extends Parser {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly T__3 = 4;
	public static readonly T__4 = 5;
	public static readonly T__5 = 6;
	public static readonly T__6 = 7;
	public static readonly T__7 = 8;
	public static readonly T__8 = 9;
	public static readonly T__9 = 10;
	public static readonly T__10 = 11;
	public static readonly T__11 = 12;
	public static readonly T__12 = 13;
	public static readonly T__13 = 14;
	public static readonly T__14 = 15;
	public static readonly T__15 = 16;
	public static readonly T__16 = 17;
	public static readonly T__17 = 18;
	public static readonly T__18 = 19;
	public static readonly T__19 = 20;
	public static readonly T__20 = 21;
	public static readonly T__21 = 22;
	public static readonly T__22 = 23;
	public static readonly T__23 = 24;
	public static readonly T__24 = 25;
	public static readonly AND = 26;
	public static readonly OR = 27;
	public static readonly EQ = 28;
	public static readonly LE = 29;
	public static readonly GE = 30;
	public static readonly NE = 31;
	public static readonly LT = 32;
	public static readonly GT = 33;
	public static readonly MINUS = 34;
	public static readonly PLUS = 35;
	public static readonly MUL = 36;
	public static readonly DIV = 37;
	public static readonly MOD = 38;
	public static readonly IN = 39;
	public static readonly LIKE = 40;
	public static readonly CONTAINS = 41;
	public static readonly STARTS_WITH = 42;
	public static readonly ENDS_WITH = 43;
	public static readonly NOT_LIKE = 44;
	public static readonly NOT_CONTAINS = 45;
	public static readonly NOT_STARTS_WITH = 46;
	public static readonly NOT_ENDS_WITH = 47;
	public static readonly AFTER = 48;
	public static readonly BEFORE = 49;
	public static readonly NOT = 50;
	public static readonly SEPARATOR = 51;
	public static readonly BooleanLiteral = 52;
	public static readonly NullLiteral = 53;
	public static readonly EmptyLiteral = 54;
	public static readonly Identifier = 55;
	public static readonly MainDateFormat = 56;
	public static readonly IsoDateFormat = 57;
	public static readonly Time12 = 58;
	public static readonly Time24 = 59;
	public static readonly IntegerLiteral = 60;
	public static readonly FloatingPointLiteral = 61;
	public static readonly SingleQuotedStringLiteral = 62;
	public static readonly DoubleQuotedStringLiteral = 63;
	public static readonly RichTextLiteral = 64;
	public static readonly WS = 65;
	public static readonly RULE_query = 0;
	public static readonly RULE_expression = 1;
	public static readonly RULE_predicate = 2;
	public static readonly RULE_path = 3;
	public static readonly RULE_pathSegment = 4;
	public static readonly RULE_termOp = 5;
	public static readonly RULE_term = 6;
	public static readonly RULE_dateTermOp = 7;
	public static readonly RULE_dateTerm = 8;
	public static readonly RULE_operator = 9;
	public static readonly RULE_unaryOperator = 10;
	public static readonly RULE_mathOperator = 11;
	public static readonly RULE_range = 12;
	public static readonly RULE_set = 13;
	public static readonly RULE_idsSet = 14;
	public static readonly RULE_amount = 15;
	public static readonly RULE_tag = 16;
	public static readonly RULE_notTag = 17;
	public static readonly RULE_filterTag = 18;
	public static readonly RULE_value = 19;
	public static readonly RULE_unit = 20;
	public static readonly RULE_dateTimeLiteral = 21;
	public static readonly RULE_dateLiteral = 22;
	public static readonly RULE_timeLiteral = 23;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"query", "expression", "predicate", "path", "pathSegment", "termOp", "term", 
		"dateTermOp", "dateTerm", "operator", "unaryOperator", "mathOperator", 
		"range", "set", "idsSet", "amount", "tag", "notTag", "filterTag", "value", 
		"unit", "dateTimeLiteral", "dateLiteral", "timeLiteral",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'('", "')'", "'{'", "'}'", "'today'", "'yesterday'", "'tomorrow'", 
		"'last week'", "'this week'", "'next week'", "'last month'", "'this month'", 
		"'next month'", "'last year'", "'this year'", "'next year'", "'now'", 
		"'me'", "'..'", "','", "'['", "']'", "'#'", "'not #'", "'@'", undefined, 
		undefined, undefined, "'<='", "'>='", undefined, "'<'", "'>'", "'-'", 
		"'+'", "'*'", "'/'", "'%'", "'in'", undefined, "'contains'", "'starts with'", 
		"'ends with'", undefined, "'not contains'", "'not starts with'", "'not ends with'", 
		"'after'", "'before'", undefined, "'.'", undefined, "'null'", "'empty'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, "AND", "OR", "EQ", 
		"LE", "GE", "NE", "LT", "GT", "MINUS", "PLUS", "MUL", "DIV", "MOD", "IN", 
		"LIKE", "CONTAINS", "STARTS_WITH", "ENDS_WITH", "NOT_LIKE", "NOT_CONTAINS", 
		"NOT_STARTS_WITH", "NOT_ENDS_WITH", "AFTER", "BEFORE", "NOT", "SEPARATOR", 
		"BooleanLiteral", "NullLiteral", "EmptyLiteral", "Identifier", "MainDateFormat", 
		"IsoDateFormat", "Time12", "Time24", "IntegerLiteral", "FloatingPointLiteral", 
		"SingleQuotedStringLiteral", "DoubleQuotedStringLiteral", "RichTextLiteral", 
		"WS",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(AqlParser._LITERAL_NAMES, AqlParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return AqlParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string { return "Aql.g4"; }

	// @Override
	public get ruleNames(): string[] { return AqlParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return AqlParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(AqlParser._ATN, this);
	}
	// @RuleVersion(0)
	public query(): QueryContext {
		let _localctx: QueryContext = new QueryContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, AqlParser.RULE_query);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 48;
			this.expression(0);
			this.state = 49;
			this.match(AqlParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public expression(): ExpressionContext;
	public expression(_p: number): ExpressionContext;
	// @RuleVersion(0)
	public expression(_p?: number): ExpressionContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: ExpressionContext = new ExpressionContext(this._ctx, _parentState);
		let _prevctx: ExpressionContext = _localctx;
		let _startState: number = 2;
		this.enterRecursionRule(_localctx, 2, AqlParser.RULE_expression, _p);
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 57;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case AqlParser.T__22:
			case AqlParser.T__23:
			case AqlParser.T__24:
			case AqlParser.LIKE:
			case AqlParser.NOT:
			case AqlParser.Identifier:
				{
				_localctx = new PredicateExpressionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;

				this.state = 52;
				this.predicate();
				}
				break;
			case AqlParser.T__0:
				{
				_localctx = new BracketExpressionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 53;
				this.match(AqlParser.T__0);
				this.state = 54;
				this.expression(0);
				this.state = 55;
				this.match(AqlParser.T__1);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 67;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					this.state = 65;
					this._errHandler.sync(this);
					switch ( this.interpreter.adaptivePredict(this._input, 1, this._ctx) ) {
					case 1:
						{
						_localctx = new AndExpressionContext(new ExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, AqlParser.RULE_expression);
						this.state = 59;
						if (!(this.precpred(this._ctx, 4))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 4)");
						}
						this.state = 60;
						this.match(AqlParser.AND);
						this.state = 61;
						this.expression(5);
						}
						break;

					case 2:
						{
						_localctx = new OrExpressionContext(new ExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, AqlParser.RULE_expression);
						this.state = 62;
						if (!(this.precpred(this._ctx, 3))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 3)");
						}
						this.state = 63;
						this.match(AqlParser.OR);
						this.state = 64;
						this.expression(4);
						}
						break;
					}
					}
				}
				this.state = 69;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public predicate(): PredicateContext {
		let _localctx: PredicateContext = new PredicateContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, AqlParser.RULE_predicate);
		let _la: number;
		try {
			let _alt: number;
			this.state = 113;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 9, this._ctx) ) {
			case 1:
				_localctx = new InPredicateContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 70;
				this.path();
				this.state = 71;
				_la = this._input.LA(1);
				if (!(_la === AqlParser.IN || _la === AqlParser.NOT)) {
				this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				this.state = 74;
				this._errHandler.sync(this);
				switch ( this.interpreter.adaptivePredict(this._input, 3, this._ctx) ) {
				case 1:
					{
					this.state = 72;
					this.set();
					}
					break;

				case 2:
					{
					this.state = 73;
					this.range();
					}
					break;
				}
				}
				break;

			case 2:
				_localctx = new OperatorPredicateContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 76;
				this.path();
				this.state = 77;
				this.operator();
				this.state = 78;
				this.termOp();
				}
				break;

			case 3:
				_localctx = new ReferencePredicateContext(_localctx);
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 81;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === AqlParser.NOT) {
					{
					this.state = 80;
					this.match(AqlParser.NOT);
					}
				}

				this.state = 83;
				this.path();
				}
				break;

			case 4:
				_localctx = new PathSegmentPredicateContext(_localctx);
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 89;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 5, this._ctx);
				while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
					if (_alt === 1) {
						{
						{
						this.state = 84;
						this.pathSegment();
						this.state = 85;
						this.match(AqlParser.SEPARATOR);
						}
						}
					}
					this.state = 91;
					this._errHandler.sync(this);
					_alt = this.interpreter.adaptivePredict(this._input, 5, this._ctx);
				}
				this.state = 92;
				this.pathSegment();
				}
				break;

			case 5:
				_localctx = new TagPredicateContext(_localctx);
				this.enterOuterAlt(_localctx, 5);
				{
				this.state = 94;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === AqlParser.Identifier) {
					{
					this.state = 93;
					this.path();
					}
				}

				this.state = 96;
				this.tag();
				}
				break;

			case 6:
				_localctx = new NotTagPredicateContext(_localctx);
				this.enterOuterAlt(_localctx, 6);
				{
				this.state = 98;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === AqlParser.Identifier) {
					{
					this.state = 97;
					this.path();
					}
				}

				this.state = 100;
				this.notTag();
				}
				break;

			case 7:
				_localctx = new UnaryOperatorPredicateContext(_localctx);
				this.enterOuterAlt(_localctx, 7);
				{
				this.state = 101;
				this.path();
				this.state = 102;
				this.unaryOperator();
				}
				break;

			case 8:
				_localctx = new EntityRootSearchContext(_localctx);
				this.enterOuterAlt(_localctx, 8);
				{
				this.state = 104;
				this.match(AqlParser.LIKE);
				this.state = 105;
				this.termOp();
				}
				break;

			case 9:
				_localctx = new FilterTagReferenceContext(_localctx);
				this.enterOuterAlt(_localctx, 9);
				{
				this.state = 106;
				this.filterTag();
				}
				break;

			case 10:
				_localctx = new IdSetPredicateContext(_localctx);
				this.enterOuterAlt(_localctx, 10);
				{
				this.state = 108;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === AqlParser.NOT) {
					{
					this.state = 107;
					this.match(AqlParser.NOT);
					}
				}

				this.state = 110;
				this.path();
				this.state = 111;
				this.idsSet();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public path(): PathContext {
		let _localctx: PathContext = new PathContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, AqlParser.RULE_path);
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 120;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 10, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 115;
					this.pathSegment();
					this.state = 116;
					this.match(AqlParser.SEPARATOR);
					}
					}
				}
				this.state = 122;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 10, this._ctx);
			}
			this.state = 123;
			this.match(AqlParser.Identifier);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public pathSegment(): PathSegmentContext {
		let _localctx: PathSegmentContext = new PathSegmentContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, AqlParser.RULE_pathSegment);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 125;
			this.match(AqlParser.Identifier);
			this.state = 130;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 11, this._ctx) ) {
			case 1:
				{
				this.state = 126;
				this.match(AqlParser.T__2);
				this.state = 127;
				this.expression(0);
				this.state = 128;
				this.match(AqlParser.T__3);
				}
				break;
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public termOp(): TermOpContext {
		let _localctx: TermOpContext = new TermOpContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, AqlParser.RULE_termOp);
		try {
			this.state = 137;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 12, this._ctx) ) {
			case 1:
				_localctx = new SingleTermContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 132;
				this.term();
				}
				break;

			case 2:
				_localctx = new MathOpContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 133;
				this.term();
				this.state = 134;
				this.mathOperator();
				this.state = 135;
				this.term();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public term(): TermContext {
		let _localctx: TermContext = new TermContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, AqlParser.RULE_term);
		try {
			this.state = 145;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 13, this._ctx) ) {
			case 1:
				_localctx = new ValueTermContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 139;
				this.value();
				}
				break;

			case 2:
				_localctx = new AmountTermContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 140;
				this.amount();
				}
				break;

			case 3:
				_localctx = new ParTermContext(_localctx);
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 141;
				this.match(AqlParser.T__0);
				this.state = 142;
				this.termOp();
				this.state = 143;
				this.match(AqlParser.T__1);
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public dateTermOp(): DateTermOpContext {
		let _localctx: DateTermOpContext = new DateTermOpContext(this._ctx, this.state);
		this.enterRule(_localctx, 14, AqlParser.RULE_dateTermOp);
		try {
			this.state = 152;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 14, this._ctx) ) {
			case 1:
				_localctx = new SingleDateTermContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 147;
				this.dateTerm();
				}
				break;

			case 2:
				_localctx = new DateMathOpContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 148;
				this.dateTerm();
				this.state = 149;
				this.mathOperator();
				this.state = 150;
				this.dateTerm();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public dateTerm(): DateTermContext {
		let _localctx: DateTermContext = new DateTermContext(this._ctx, this.state);
		this.enterRule(_localctx, 16, AqlParser.RULE_dateTerm);
		try {
			this.state = 157;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case AqlParser.T__4:
			case AqlParser.T__5:
			case AqlParser.T__6:
			case AqlParser.T__7:
			case AqlParser.T__8:
			case AqlParser.T__9:
			case AqlParser.T__10:
			case AqlParser.T__11:
			case AqlParser.T__12:
			case AqlParser.T__13:
			case AqlParser.T__14:
			case AqlParser.T__15:
			case AqlParser.T__16:
			case AqlParser.T__17:
				_localctx = new UnaryTermContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 154;
				this.unaryOperator();
				}
				break;
			case AqlParser.IntegerLiteral:
				_localctx = new DateAmountTermContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 155;
				this.amount();
				}
				break;
			case AqlParser.MainDateFormat:
			case AqlParser.IsoDateFormat:
				_localctx = new DateTimeLiteralTermContext(_localctx);
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 156;
				this.dateTimeLiteral();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public operator(): OperatorContext {
		let _localctx: OperatorContext = new OperatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 18, AqlParser.RULE_operator);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 159;
			_la = this._input.LA(1);
			if (!(((((_la - 28)) & ~0x1F) === 0 && ((1 << (_la - 28)) & ((1 << (AqlParser.EQ - 28)) | (1 << (AqlParser.LE - 28)) | (1 << (AqlParser.GE - 28)) | (1 << (AqlParser.NE - 28)) | (1 << (AqlParser.LT - 28)) | (1 << (AqlParser.GT - 28)) | (1 << (AqlParser.LIKE - 28)) | (1 << (AqlParser.CONTAINS - 28)) | (1 << (AqlParser.STARTS_WITH - 28)) | (1 << (AqlParser.ENDS_WITH - 28)) | (1 << (AqlParser.NOT_LIKE - 28)) | (1 << (AqlParser.NOT_CONTAINS - 28)) | (1 << (AqlParser.NOT_STARTS_WITH - 28)) | (1 << (AqlParser.NOT_ENDS_WITH - 28)) | (1 << (AqlParser.AFTER - 28)) | (1 << (AqlParser.BEFORE - 28)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public unaryOperator(): UnaryOperatorContext {
		let _localctx: UnaryOperatorContext = new UnaryOperatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 20, AqlParser.RULE_unaryOperator);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 161;
			_la = this._input.LA(1);
			if (!((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << AqlParser.T__4) | (1 << AqlParser.T__5) | (1 << AqlParser.T__6) | (1 << AqlParser.T__7) | (1 << AqlParser.T__8) | (1 << AqlParser.T__9) | (1 << AqlParser.T__10) | (1 << AqlParser.T__11) | (1 << AqlParser.T__12) | (1 << AqlParser.T__13) | (1 << AqlParser.T__14) | (1 << AqlParser.T__15) | (1 << AqlParser.T__16) | (1 << AqlParser.T__17))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public mathOperator(): MathOperatorContext {
		let _localctx: MathOperatorContext = new MathOperatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 22, AqlParser.RULE_mathOperator);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 163;
			_la = this._input.LA(1);
			if (!(((((_la - 34)) & ~0x1F) === 0 && ((1 << (_la - 34)) & ((1 << (AqlParser.MINUS - 34)) | (1 << (AqlParser.PLUS - 34)) | (1 << (AqlParser.MUL - 34)) | (1 << (AqlParser.DIV - 34)) | (1 << (AqlParser.MOD - 34)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public range(): RangeContext {
		let _localctx: RangeContext = new RangeContext(this._ctx, this.state);
		this.enterRule(_localctx, 24, AqlParser.RULE_range);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 167;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case AqlParser.T__4:
			case AqlParser.T__5:
			case AqlParser.T__6:
			case AqlParser.T__7:
			case AqlParser.T__8:
			case AqlParser.T__9:
			case AqlParser.T__10:
			case AqlParser.T__11:
			case AqlParser.T__12:
			case AqlParser.T__13:
			case AqlParser.T__14:
			case AqlParser.T__15:
			case AqlParser.T__16:
			case AqlParser.T__17:
			case AqlParser.MainDateFormat:
			case AqlParser.IsoDateFormat:
			case AqlParser.IntegerLiteral:
				{
				this.state = 165;
				this.dateTermOp();
				}
				break;
			case AqlParser.MUL:
				{
				this.state = 166;
				this.match(AqlParser.MUL);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this.state = 169;
			this.match(AqlParser.T__18);
			this.state = 172;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case AqlParser.T__4:
			case AqlParser.T__5:
			case AqlParser.T__6:
			case AqlParser.T__7:
			case AqlParser.T__8:
			case AqlParser.T__9:
			case AqlParser.T__10:
			case AqlParser.T__11:
			case AqlParser.T__12:
			case AqlParser.T__13:
			case AqlParser.T__14:
			case AqlParser.T__15:
			case AqlParser.T__16:
			case AqlParser.T__17:
			case AqlParser.MainDateFormat:
			case AqlParser.IsoDateFormat:
			case AqlParser.IntegerLiteral:
				{
				this.state = 170;
				this.dateTermOp();
				}
				break;
			case AqlParser.MUL:
				{
				this.state = 171;
				this.match(AqlParser.MUL);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public set(): SetContext {
		let _localctx: SetContext = new SetContext(this._ctx, this.state);
		this.enterRule(_localctx, 26, AqlParser.RULE_set);
		let _la: number;
		try {
			let _alt: number;
			this.state = 195;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case AqlParser.T__0:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 174;
				this.match(AqlParser.T__0);
				this.state = 175;
				this.value();
				this.state = 180;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === AqlParser.T__19) {
					{
					{
					this.state = 176;
					this.match(AqlParser.T__19);
					this.state = 177;
					this.value();
					}
					}
					this.state = 182;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 183;
				this.match(AqlParser.T__1);
				}
				break;
			case AqlParser.T__4:
			case AqlParser.T__5:
			case AqlParser.T__6:
			case AqlParser.T__7:
			case AqlParser.T__8:
			case AqlParser.T__9:
			case AqlParser.T__10:
			case AqlParser.T__11:
			case AqlParser.T__12:
			case AqlParser.T__13:
			case AqlParser.T__14:
			case AqlParser.T__15:
			case AqlParser.T__16:
			case AqlParser.T__17:
			case AqlParser.BooleanLiteral:
			case AqlParser.NullLiteral:
			case AqlParser.EmptyLiteral:
			case AqlParser.Identifier:
			case AqlParser.MainDateFormat:
			case AqlParser.IsoDateFormat:
			case AqlParser.IntegerLiteral:
			case AqlParser.FloatingPointLiteral:
			case AqlParser.SingleQuotedStringLiteral:
			case AqlParser.DoubleQuotedStringLiteral:
			case AqlParser.RichTextLiteral:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 185;
				this.value();
				this.state = 186;
				this.match(AqlParser.T__19);
				this.state = 187;
				this.value();
				this.state = 192;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 19, this._ctx);
				while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
					if (_alt === 1) {
						{
						{
						this.state = 188;
						this.match(AqlParser.T__19);
						this.state = 189;
						this.value();
						}
						}
					}
					this.state = 194;
					this._errHandler.sync(this);
					_alt = this.interpreter.adaptivePredict(this._input, 19, this._ctx);
				}
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public idsSet(): IdsSetContext {
		let _localctx: IdsSetContext = new IdsSetContext(this._ctx, this.state);
		this.enterRule(_localctx, 28, AqlParser.RULE_idsSet);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 197;
			this.match(AqlParser.T__20);
			this.state = 198;
			this.value();
			this.state = 203;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === AqlParser.T__19) {
				{
				{
				this.state = 199;
				this.match(AqlParser.T__19);
				this.state = 200;
				this.value();
				}
				}
				this.state = 205;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 206;
			this.match(AqlParser.T__21);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public amount(): AmountContext {
		let _localctx: AmountContext = new AmountContext(this._ctx, this.state);
		this.enterRule(_localctx, 30, AqlParser.RULE_amount);
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 210;
			this._errHandler.sync(this);
			_alt = 1;
			do {
				switch (_alt) {
				case 1:
					{
					{
					this.state = 208;
					this.match(AqlParser.IntegerLiteral);
					this.state = 209;
					this.unit();
					}
					}
					break;
				default:
					throw new NoViableAltException(this);
				}
				this.state = 212;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 22, this._ctx);
			} while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public tag(): TagContext {
		let _localctx: TagContext = new TagContext(this._ctx, this.state);
		this.enterRule(_localctx, 32, AqlParser.RULE_tag);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 214;
			this.match(AqlParser.T__22);
			this.state = 215;
			_la = this._input.LA(1);
			if (!(((((_la - 55)) & ~0x1F) === 0 && ((1 << (_la - 55)) & ((1 << (AqlParser.Identifier - 55)) | (1 << (AqlParser.SingleQuotedStringLiteral - 55)) | (1 << (AqlParser.DoubleQuotedStringLiteral - 55)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public notTag(): NotTagContext {
		let _localctx: NotTagContext = new NotTagContext(this._ctx, this.state);
		this.enterRule(_localctx, 34, AqlParser.RULE_notTag);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 217;
			this.match(AqlParser.T__23);
			this.state = 218;
			_la = this._input.LA(1);
			if (!(((((_la - 55)) & ~0x1F) === 0 && ((1 << (_la - 55)) & ((1 << (AqlParser.Identifier - 55)) | (1 << (AqlParser.SingleQuotedStringLiteral - 55)) | (1 << (AqlParser.DoubleQuotedStringLiteral - 55)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public filterTag(): FilterTagContext {
		let _localctx: FilterTagContext = new FilterTagContext(this._ctx, this.state);
		this.enterRule(_localctx, 36, AqlParser.RULE_filterTag);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 220;
			this.match(AqlParser.T__24);
			this.state = 221;
			_la = this._input.LA(1);
			if (!(((((_la - 55)) & ~0x1F) === 0 && ((1 << (_la - 55)) & ((1 << (AqlParser.Identifier - 55)) | (1 << (AqlParser.SingleQuotedStringLiteral - 55)) | (1 << (AqlParser.DoubleQuotedStringLiteral - 55)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public value(): ValueContext {
		let _localctx: ValueContext = new ValueContext(this._ctx, this.state);
		this.enterRule(_localctx, 38, AqlParser.RULE_value);
		try {
			this.state = 234;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case AqlParser.MainDateFormat:
			case AqlParser.IsoDateFormat:
				_localctx = new DateTimeContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 223;
				this.dateTimeLiteral();
				}
				break;
			case AqlParser.IntegerLiteral:
				_localctx = new IntContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 224;
				this.match(AqlParser.IntegerLiteral);
				}
				break;
			case AqlParser.FloatingPointLiteral:
				_localctx = new FloatContext(_localctx);
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 225;
				this.match(AqlParser.FloatingPointLiteral);
				}
				break;
			case AqlParser.SingleQuotedStringLiteral:
				_localctx = new StringContext(_localctx);
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 226;
				this.match(AqlParser.SingleQuotedStringLiteral);
				}
				break;
			case AqlParser.DoubleQuotedStringLiteral:
				_localctx = new StringContext(_localctx);
				this.enterOuterAlt(_localctx, 5);
				{
				this.state = 227;
				this.match(AqlParser.DoubleQuotedStringLiteral);
				}
				break;
			case AqlParser.RichTextLiteral:
				_localctx = new RichTextContext(_localctx);
				this.enterOuterAlt(_localctx, 6);
				{
				this.state = 228;
				this.match(AqlParser.RichTextLiteral);
				}
				break;
			case AqlParser.BooleanLiteral:
				_localctx = new BooleanContext(_localctx);
				this.enterOuterAlt(_localctx, 7);
				{
				this.state = 229;
				this.match(AqlParser.BooleanLiteral);
				}
				break;
			case AqlParser.NullLiteral:
				_localctx = new NullContext(_localctx);
				this.enterOuterAlt(_localctx, 8);
				{
				this.state = 230;
				this.match(AqlParser.NullLiteral);
				}
				break;
			case AqlParser.EmptyLiteral:
				_localctx = new EmptyContext(_localctx);
				this.enterOuterAlt(_localctx, 9);
				{
				this.state = 231;
				this.match(AqlParser.EmptyLiteral);
				}
				break;
			case AqlParser.Identifier:
				_localctx = new IdContext(_localctx);
				this.enterOuterAlt(_localctx, 10);
				{
				this.state = 232;
				this.match(AqlParser.Identifier);
				}
				break;
			case AqlParser.T__4:
			case AqlParser.T__5:
			case AqlParser.T__6:
			case AqlParser.T__7:
			case AqlParser.T__8:
			case AqlParser.T__9:
			case AqlParser.T__10:
			case AqlParser.T__11:
			case AqlParser.T__12:
			case AqlParser.T__13:
			case AqlParser.T__14:
			case AqlParser.T__15:
			case AqlParser.T__16:
			case AqlParser.T__17:
				_localctx = new IdContext(_localctx);
				this.enterOuterAlt(_localctx, 11);
				{
				this.state = 233;
				this.unaryOperator();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public unit(): UnitContext {
		let _localctx: UnitContext = new UnitContext(this._ctx, this.state);
		this.enterRule(_localctx, 40, AqlParser.RULE_unit);
		try {
			this.state = 238;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case AqlParser.MOD:
				_localctx = new UnitPercentContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 236;
				this.match(AqlParser.MOD);
				}
				break;
			case AqlParser.Identifier:
				_localctx = new UnitCustomContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 237;
				this.match(AqlParser.Identifier);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public dateTimeLiteral(): DateTimeLiteralContext {
		let _localctx: DateTimeLiteralContext = new DateTimeLiteralContext(this._ctx, this.state);
		this.enterRule(_localctx, 42, AqlParser.RULE_dateTimeLiteral);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 240;
			this.dateLiteral();
			this.state = 242;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 25, this._ctx) ) {
			case 1:
				{
				this.state = 241;
				this.timeLiteral();
				}
				break;
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public dateLiteral(): DateLiteralContext {
		let _localctx: DateLiteralContext = new DateLiteralContext(this._ctx, this.state);
		this.enterRule(_localctx, 44, AqlParser.RULE_dateLiteral);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 244;
			_la = this._input.LA(1);
			if (!(_la === AqlParser.MainDateFormat || _la === AqlParser.IsoDateFormat)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public timeLiteral(): TimeLiteralContext {
		let _localctx: TimeLiteralContext = new TimeLiteralContext(this._ctx, this.state);
		this.enterRule(_localctx, 46, AqlParser.RULE_timeLiteral);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 246;
			_la = this._input.LA(1);
			if (!(_la === AqlParser.Time12 || _la === AqlParser.Time24)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public sempred(_localctx: RuleContext, ruleIndex: number, predIndex: number): boolean {
		switch (ruleIndex) {
		case 1:
			return this.expression_sempred(_localctx as ExpressionContext, predIndex);
		}
		return true;
	}
	private expression_sempred(_localctx: ExpressionContext, predIndex: number): boolean {
		switch (predIndex) {
		case 0:
			return this.precpred(this._ctx, 4);

		case 1:
			return this.precpred(this._ctx, 3);
		}
		return true;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03C\xFB\x04\x02" +
		"\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07" +
		"\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r\t\r\x04" +
		"\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t\x12\x04" +
		"\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t\x17\x04" +
		"\x18\t\x18\x04\x19\t\x19\x03\x02\x03\x02\x03\x02\x03\x03\x03\x03\x03\x03" +
		"\x03\x03\x03\x03\x03\x03\x05\x03<\n\x03\x03\x03\x03\x03\x03\x03\x03\x03" +
		"\x03\x03\x03\x03\x07\x03D\n\x03\f\x03\x0E\x03G\v\x03\x03\x04\x03\x04\x03" +
		"\x04\x03\x04\x05\x04M\n\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x05" +
		"\x04T\n\x04\x03\x04\x03\x04\x03\x04\x03\x04\x07\x04Z\n\x04\f\x04\x0E\x04" +
		"]\v\x04\x03\x04\x03\x04\x05\x04a\n\x04\x03\x04\x03\x04\x05\x04e\n\x04" +
		"\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x05\x04" +
		"o\n\x04\x03\x04\x03\x04\x03\x04\x05\x04t\n\x04\x03\x05\x03\x05\x03\x05" +
		"\x07\x05y\n\x05\f\x05\x0E\x05|\v\x05\x03\x05\x03\x05\x03\x06\x03\x06\x03" +
		"\x06\x03\x06\x03\x06\x05\x06\x85\n\x06\x03\x07\x03\x07\x03\x07\x03\x07" +
		"\x03\x07\x05\x07\x8C\n\x07\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x05\b\x94" +
		"\n\b\x03\t\x03\t\x03\t\x03\t\x03\t\x05\t\x9B\n\t\x03\n\x03\n\x03\n\x05" +
		"\n\xA0\n\n\x03\v\x03\v\x03\f\x03\f\x03\r\x03\r\x03\x0E\x03\x0E\x05\x0E" +
		"\xAA\n\x0E\x03\x0E\x03\x0E\x03\x0E\x05\x0E\xAF\n\x0E\x03\x0F\x03\x0F\x03" +
		"\x0F\x03\x0F\x07\x0F\xB5\n\x0F\f\x0F\x0E\x0F\xB8\v\x0F\x03\x0F\x03\x0F" +
		"\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x07\x0F\xC1\n\x0F\f\x0F\x0E\x0F" +
		"\xC4\v\x0F\x05\x0F\xC6\n\x0F\x03\x10\x03\x10\x03\x10\x03\x10\x07\x10\xCC" +
		"\n\x10\f\x10\x0E\x10\xCF\v\x10\x03\x10\x03\x10\x03\x11\x03\x11\x06\x11" +
		"\xD5\n\x11\r\x11\x0E\x11\xD6\x03\x12\x03\x12\x03\x12\x03\x13\x03\x13\x03" +
		"\x13\x03\x14\x03\x14\x03\x14\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15\x03" +
		"\x15\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15\x05\x15\xED\n\x15\x03\x16" +
		"\x03\x16\x05\x16\xF1\n\x16\x03\x17\x03\x17\x05\x17\xF5\n\x17\x03\x18\x03" +
		"\x18\x03\x19\x03\x19\x03\x19\x02\x02\x03\x04\x1A\x02\x02\x04\x02\x06\x02" +
		"\b\x02\n\x02\f\x02\x0E\x02\x10\x02\x12\x02\x14\x02\x16\x02\x18\x02\x1A" +
		"\x02\x1C\x02\x1E\x02 \x02\"\x02$\x02&\x02(\x02*\x02,\x02.\x020\x02\x02" +
		"\t\x04\x02))44\x04\x02\x1E#*3\x03\x02\x07\x14\x03\x02$(\x04\x0299@A\x03" +
		"\x02:;\x03\x02<=\x02\u010F\x022\x03\x02\x02\x02\x04;\x03\x02\x02\x02\x06" +
		"s\x03\x02\x02\x02\bz\x03\x02\x02\x02\n\x7F\x03\x02\x02\x02\f\x8B\x03\x02" +
		"\x02\x02\x0E\x93\x03\x02\x02\x02\x10\x9A\x03\x02\x02\x02\x12\x9F\x03\x02" +
		"\x02\x02\x14\xA1\x03\x02\x02\x02\x16\xA3\x03\x02\x02\x02\x18\xA5\x03\x02" +
		"\x02\x02\x1A\xA9\x03\x02\x02\x02\x1C\xC5\x03\x02\x02\x02\x1E\xC7\x03\x02" +
		"\x02\x02 \xD4\x03\x02\x02\x02\"\xD8\x03\x02\x02\x02$\xDB\x03\x02\x02\x02" +
		"&\xDE\x03\x02\x02\x02(\xEC\x03\x02\x02\x02*\xF0\x03\x02\x02\x02,\xF2\x03" +
		"\x02\x02\x02.\xF6\x03\x02\x02\x020\xF8\x03\x02\x02\x0223\x05\x04\x03\x02" +
		"34\x07\x02\x02\x034\x03\x03\x02\x02\x0256\b\x03\x01\x026<\x05\x06\x04" +
		"\x0278\x07\x03\x02\x0289\x05\x04\x03\x029:\x07\x04\x02\x02:<\x03\x02\x02" +
		"\x02;5\x03\x02\x02\x02;7\x03\x02\x02\x02<E\x03\x02\x02\x02=>\f\x06\x02" +
		"\x02>?\x07\x1C\x02\x02?D\x05\x04\x03\x07@A\f\x05\x02\x02AB\x07\x1D\x02" +
		"\x02BD\x05\x04\x03\x06C=\x03\x02\x02\x02C@\x03\x02\x02\x02DG\x03\x02\x02" +
		"\x02EC\x03\x02\x02\x02EF\x03\x02\x02\x02F\x05\x03\x02\x02\x02GE\x03\x02" +
		"\x02\x02HI\x05\b\x05\x02IL\t\x02\x02\x02JM\x05\x1C\x0F\x02KM\x05\x1A\x0E" +
		"\x02LJ\x03\x02\x02\x02LK\x03\x02\x02\x02Mt\x03\x02\x02\x02NO\x05\b\x05" +
		"\x02OP\x05\x14\v\x02PQ\x05\f\x07\x02Qt\x03\x02\x02\x02RT\x074\x02\x02" +
		"SR\x03\x02\x02\x02ST\x03\x02\x02\x02TU\x03\x02\x02\x02Ut\x05\b\x05\x02" +
		"VW\x05\n\x06\x02WX\x075\x02\x02XZ\x03\x02\x02\x02YV\x03\x02\x02\x02Z]" +
		"\x03\x02\x02\x02[Y\x03\x02\x02\x02[\\\x03\x02\x02\x02\\^\x03\x02\x02\x02" +
		"][\x03\x02\x02\x02^t\x05\n\x06\x02_a\x05\b\x05\x02`_\x03\x02\x02\x02`" +
		"a\x03\x02\x02\x02ab\x03\x02\x02\x02bt\x05\"\x12\x02ce\x05\b\x05\x02dc" +
		"\x03\x02\x02\x02de\x03\x02\x02\x02ef\x03\x02\x02\x02ft\x05$\x13\x02gh" +
		"\x05\b\x05\x02hi\x05\x16\f\x02it\x03\x02\x02\x02jk\x07*\x02\x02kt\x05" +
		"\f\x07\x02lt\x05&\x14\x02mo\x074\x02\x02nm\x03\x02\x02\x02no\x03\x02\x02" +
		"\x02op\x03\x02\x02\x02pq\x05\b\x05\x02qr\x05\x1E\x10\x02rt\x03\x02\x02" +
		"\x02sH\x03\x02\x02\x02sN\x03\x02\x02\x02sS\x03\x02\x02\x02s[\x03\x02\x02" +
		"\x02s`\x03\x02\x02\x02sd\x03\x02\x02\x02sg\x03\x02\x02\x02sj\x03\x02\x02" +
		"\x02sl\x03\x02\x02\x02sn\x03\x02\x02\x02t\x07\x03\x02\x02\x02uv\x05\n" +
		"\x06\x02vw\x075\x02\x02wy\x03\x02\x02\x02xu\x03\x02\x02\x02y|\x03\x02" +
		"\x02\x02zx\x03\x02\x02\x02z{\x03\x02\x02\x02{}\x03\x02\x02\x02|z\x03\x02" +
		"\x02\x02}~\x079\x02\x02~\t\x03\x02\x02\x02\x7F\x84\x079\x02\x02\x80\x81" +
		"\x07\x05\x02\x02\x81\x82\x05\x04\x03\x02\x82\x83\x07\x06\x02\x02\x83\x85" +
		"\x03\x02\x02\x02\x84\x80\x03\x02\x02\x02\x84\x85\x03\x02\x02\x02\x85\v" +
		"\x03\x02\x02\x02\x86\x8C\x05\x0E\b\x02\x87\x88\x05\x0E\b\x02\x88\x89\x05" +
		"\x18\r\x02\x89\x8A\x05\x0E\b\x02\x8A\x8C\x03\x02\x02\x02\x8B\x86\x03\x02" +
		"\x02\x02\x8B\x87\x03\x02\x02\x02\x8C\r\x03\x02\x02\x02\x8D\x94\x05(\x15" +
		"\x02\x8E\x94\x05 \x11\x02\x8F\x90\x07\x03\x02\x02\x90\x91\x05\f\x07\x02" +
		"\x91\x92\x07\x04\x02\x02\x92\x94\x03\x02\x02\x02\x93\x8D\x03\x02\x02\x02" +
		"\x93\x8E\x03\x02\x02\x02\x93\x8F\x03\x02\x02\x02\x94\x0F\x03\x02\x02\x02" +
		"\x95\x9B\x05\x12\n\x02\x96\x97\x05\x12\n\x02\x97\x98\x05\x18\r\x02\x98" +
		"\x99\x05\x12\n\x02\x99\x9B\x03\x02\x02\x02\x9A\x95\x03\x02\x02\x02\x9A" +
		"\x96\x03\x02\x02\x02\x9B\x11\x03\x02\x02\x02\x9C\xA0\x05\x16\f\x02\x9D" +
		"\xA0\x05 \x11\x02\x9E\xA0\x05,\x17\x02\x9F\x9C\x03\x02\x02\x02\x9F\x9D" +
		"\x03\x02\x02\x02\x9F\x9E\x03\x02\x02\x02\xA0\x13\x03\x02\x02\x02\xA1\xA2" +
		"\t\x03\x02\x02\xA2\x15\x03\x02\x02\x02\xA3\xA4\t\x04\x02\x02\xA4\x17\x03" +
		"\x02\x02\x02\xA5\xA6\t\x05\x02\x02\xA6\x19\x03\x02\x02\x02\xA7\xAA\x05" +
		"\x10\t\x02\xA8\xAA\x07&\x02\x02\xA9\xA7\x03\x02\x02\x02\xA9\xA8\x03\x02" +
		"\x02\x02\xAA\xAB\x03\x02\x02\x02\xAB\xAE\x07\x15\x02\x02\xAC\xAF\x05\x10" +
		"\t\x02\xAD\xAF\x07&\x02\x02\xAE\xAC\x03\x02\x02\x02\xAE\xAD\x03\x02\x02" +
		"\x02\xAF\x1B\x03\x02\x02\x02\xB0\xB1\x07\x03\x02\x02\xB1\xB6\x05(\x15" +
		"\x02\xB2\xB3\x07\x16\x02\x02\xB3\xB5\x05(\x15\x02\xB4\xB2\x03\x02\x02" +
		"\x02\xB5\xB8\x03\x02\x02\x02\xB6\xB4\x03\x02\x02\x02\xB6\xB7\x03\x02\x02" +
		"\x02\xB7\xB9\x03\x02\x02\x02\xB8\xB6\x03\x02\x02\x02\xB9\xBA\x07\x04\x02" +
		"\x02\xBA\xC6\x03\x02\x02\x02\xBB\xBC\x05(\x15\x02\xBC\xBD\x07\x16\x02" +
		"\x02\xBD\xC2\x05(\x15\x02\xBE\xBF\x07\x16\x02\x02\xBF\xC1\x05(\x15\x02" +
		"\xC0\xBE\x03\x02\x02\x02\xC1\xC4\x03\x02\x02\x02\xC2\xC0\x03\x02\x02\x02" +
		"\xC2\xC3\x03\x02\x02\x02\xC3\xC6\x03\x02\x02\x02\xC4\xC2\x03\x02\x02\x02" +
		"\xC5\xB0\x03\x02\x02\x02\xC5\xBB\x03\x02\x02\x02\xC6\x1D\x03\x02\x02\x02" +
		"\xC7\xC8\x07\x17\x02\x02\xC8\xCD\x05(\x15\x02\xC9\xCA\x07\x16\x02\x02" +
		"\xCA\xCC\x05(\x15\x02\xCB\xC9\x03\x02\x02\x02\xCC\xCF\x03\x02\x02\x02" +
		"\xCD\xCB\x03\x02\x02\x02\xCD\xCE\x03\x02\x02\x02\xCE\xD0\x03\x02\x02\x02" +
		"\xCF\xCD\x03\x02\x02\x02\xD0\xD1\x07\x18\x02\x02\xD1\x1F\x03\x02\x02\x02" +
		"\xD2\xD3\x07>\x02\x02\xD3\xD5\x05*\x16\x02\xD4\xD2\x03\x02\x02\x02\xD5" +
		"\xD6\x03\x02\x02\x02\xD6\xD4\x03\x02\x02\x02\xD6\xD7\x03\x02\x02\x02\xD7" +
		"!\x03\x02\x02\x02\xD8\xD9\x07\x19\x02\x02\xD9\xDA\t\x06\x02\x02\xDA#\x03" +
		"\x02\x02\x02\xDB\xDC\x07\x1A\x02\x02\xDC\xDD\t\x06\x02\x02\xDD%\x03\x02" +
		"\x02\x02\xDE\xDF\x07\x1B\x02\x02\xDF\xE0\t\x06\x02\x02\xE0\'\x03\x02\x02" +
		"\x02\xE1\xED\x05,\x17\x02\xE2\xED\x07>\x02\x02\xE3\xED\x07?\x02\x02\xE4" +
		"\xED\x07@\x02\x02\xE5\xED\x07A\x02\x02\xE6\xED\x07B\x02\x02\xE7\xED\x07" +
		"6\x02\x02\xE8\xED\x077\x02\x02\xE9\xED\x078\x02\x02\xEA\xED\x079\x02\x02" +
		"\xEB\xED\x05\x16\f\x02\xEC\xE1\x03\x02\x02\x02\xEC\xE2\x03\x02\x02\x02" +
		"\xEC\xE3\x03\x02\x02\x02\xEC\xE4\x03\x02\x02\x02\xEC\xE5\x03\x02\x02\x02" +
		"\xEC\xE6\x03\x02\x02\x02\xEC\xE7\x03\x02\x02\x02\xEC\xE8\x03\x02\x02\x02" +
		"\xEC\xE9\x03\x02\x02\x02\xEC\xEA\x03\x02\x02\x02\xEC\xEB\x03\x02\x02\x02" +
		"\xED)\x03\x02\x02\x02\xEE\xF1\x07(\x02\x02\xEF\xF1\x079\x02\x02\xF0\xEE" +
		"\x03\x02\x02\x02\xF0\xEF\x03\x02\x02\x02\xF1+\x03\x02\x02\x02\xF2\xF4" +
		"\x05.\x18\x02\xF3\xF5\x050\x19\x02\xF4\xF3\x03\x02\x02\x02\xF4\xF5\x03" +
		"\x02\x02\x02\xF5-\x03\x02\x02\x02\xF6\xF7\t\x07\x02\x02\xF7/\x03\x02\x02" +
		"\x02\xF8\xF9\t\b\x02\x02\xF91\x03\x02\x02\x02\x1C;CELS[`dnsz\x84\x8B\x93" +
		"\x9A\x9F\xA9\xAE\xB6\xC2\xC5\xCD\xD6\xEC\xF0\xF4";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!AqlParser.__ATN) {
			AqlParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(AqlParser._serializedATN));
		}

		return AqlParser.__ATN;
	}

}

export class QueryContext extends ParserRuleContext {
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public EOF(): TerminalNode { return this.getToken(AqlParser.EOF, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_query; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterQuery) {
			listener.enterQuery(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitQuery) {
			listener.exitQuery(this);
		}
	}
}


export class ExpressionContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_expression; }
	public copyFrom(ctx: ExpressionContext): void {
		super.copyFrom(ctx);
	}
}
export class AndExpressionContext extends ExpressionContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public AND(): TerminalNode { return this.getToken(AqlParser.AND, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterAndExpression) {
			listener.enterAndExpression(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitAndExpression) {
			listener.exitAndExpression(this);
		}
	}
}
export class OrExpressionContext extends ExpressionContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public OR(): TerminalNode { return this.getToken(AqlParser.OR, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterOrExpression) {
			listener.enterOrExpression(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitOrExpression) {
			listener.exitOrExpression(this);
		}
	}
}
export class PredicateExpressionContext extends ExpressionContext {
	public predicate(): PredicateContext {
		return this.getRuleContext(0, PredicateContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterPredicateExpression) {
			listener.enterPredicateExpression(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitPredicateExpression) {
			listener.exitPredicateExpression(this);
		}
	}
}
export class BracketExpressionContext extends ExpressionContext {
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterBracketExpression) {
			listener.enterBracketExpression(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitBracketExpression) {
			listener.exitBracketExpression(this);
		}
	}
}


export class PredicateContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_predicate; }
	public copyFrom(ctx: PredicateContext): void {
		super.copyFrom(ctx);
	}
}
export class InPredicateContext extends PredicateContext {
	public path(): PathContext {
		return this.getRuleContext(0, PathContext);
	}
	public NOT(): TerminalNode | undefined { return this.tryGetToken(AqlParser.NOT, 0); }
	public IN(): TerminalNode | undefined { return this.tryGetToken(AqlParser.IN, 0); }
	public set(): SetContext | undefined {
		return this.tryGetRuleContext(0, SetContext);
	}
	public range(): RangeContext | undefined {
		return this.tryGetRuleContext(0, RangeContext);
	}
	constructor(ctx: PredicateContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterInPredicate) {
			listener.enterInPredicate(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitInPredicate) {
			listener.exitInPredicate(this);
		}
	}
}
export class OperatorPredicateContext extends PredicateContext {
	public path(): PathContext {
		return this.getRuleContext(0, PathContext);
	}
	public operator(): OperatorContext {
		return this.getRuleContext(0, OperatorContext);
	}
	public termOp(): TermOpContext {
		return this.getRuleContext(0, TermOpContext);
	}
	constructor(ctx: PredicateContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterOperatorPredicate) {
			listener.enterOperatorPredicate(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitOperatorPredicate) {
			listener.exitOperatorPredicate(this);
		}
	}
}
export class ReferencePredicateContext extends PredicateContext {
	public path(): PathContext {
		return this.getRuleContext(0, PathContext);
	}
	public NOT(): TerminalNode | undefined { return this.tryGetToken(AqlParser.NOT, 0); }
	constructor(ctx: PredicateContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterReferencePredicate) {
			listener.enterReferencePredicate(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitReferencePredicate) {
			listener.exitReferencePredicate(this);
		}
	}
}
export class PathSegmentPredicateContext extends PredicateContext {
	public pathSegment(): PathSegmentContext[];
	public pathSegment(i: number): PathSegmentContext;
	public pathSegment(i?: number): PathSegmentContext | PathSegmentContext[] {
		if (i === undefined) {
			return this.getRuleContexts(PathSegmentContext);
		} else {
			return this.getRuleContext(i, PathSegmentContext);
		}
	}
	public SEPARATOR(): TerminalNode[];
	public SEPARATOR(i: number): TerminalNode;
	public SEPARATOR(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(AqlParser.SEPARATOR);
		} else {
			return this.getToken(AqlParser.SEPARATOR, i);
		}
	}
	constructor(ctx: PredicateContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterPathSegmentPredicate) {
			listener.enterPathSegmentPredicate(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitPathSegmentPredicate) {
			listener.exitPathSegmentPredicate(this);
		}
	}
}
export class TagPredicateContext extends PredicateContext {
	public tag(): TagContext {
		return this.getRuleContext(0, TagContext);
	}
	public path(): PathContext | undefined {
		return this.tryGetRuleContext(0, PathContext);
	}
	constructor(ctx: PredicateContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterTagPredicate) {
			listener.enterTagPredicate(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitTagPredicate) {
			listener.exitTagPredicate(this);
		}
	}
}
export class NotTagPredicateContext extends PredicateContext {
	public notTag(): NotTagContext {
		return this.getRuleContext(0, NotTagContext);
	}
	public path(): PathContext | undefined {
		return this.tryGetRuleContext(0, PathContext);
	}
	constructor(ctx: PredicateContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterNotTagPredicate) {
			listener.enterNotTagPredicate(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitNotTagPredicate) {
			listener.exitNotTagPredicate(this);
		}
	}
}
export class UnaryOperatorPredicateContext extends PredicateContext {
	public path(): PathContext {
		return this.getRuleContext(0, PathContext);
	}
	public unaryOperator(): UnaryOperatorContext {
		return this.getRuleContext(0, UnaryOperatorContext);
	}
	constructor(ctx: PredicateContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterUnaryOperatorPredicate) {
			listener.enterUnaryOperatorPredicate(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitUnaryOperatorPredicate) {
			listener.exitUnaryOperatorPredicate(this);
		}
	}
}
export class EntityRootSearchContext extends PredicateContext {
	public LIKE(): TerminalNode { return this.getToken(AqlParser.LIKE, 0); }
	public termOp(): TermOpContext {
		return this.getRuleContext(0, TermOpContext);
	}
	constructor(ctx: PredicateContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterEntityRootSearch) {
			listener.enterEntityRootSearch(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitEntityRootSearch) {
			listener.exitEntityRootSearch(this);
		}
	}
}
export class FilterTagReferenceContext extends PredicateContext {
	public filterTag(): FilterTagContext {
		return this.getRuleContext(0, FilterTagContext);
	}
	constructor(ctx: PredicateContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterFilterTagReference) {
			listener.enterFilterTagReference(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitFilterTagReference) {
			listener.exitFilterTagReference(this);
		}
	}
}
export class IdSetPredicateContext extends PredicateContext {
	public path(): PathContext {
		return this.getRuleContext(0, PathContext);
	}
	public idsSet(): IdsSetContext {
		return this.getRuleContext(0, IdsSetContext);
	}
	public NOT(): TerminalNode | undefined { return this.tryGetToken(AqlParser.NOT, 0); }
	constructor(ctx: PredicateContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterIdSetPredicate) {
			listener.enterIdSetPredicate(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitIdSetPredicate) {
			listener.exitIdSetPredicate(this);
		}
	}
}


export class PathContext extends ParserRuleContext {
	public Identifier(): TerminalNode { return this.getToken(AqlParser.Identifier, 0); }
	public pathSegment(): PathSegmentContext[];
	public pathSegment(i: number): PathSegmentContext;
	public pathSegment(i?: number): PathSegmentContext | PathSegmentContext[] {
		if (i === undefined) {
			return this.getRuleContexts(PathSegmentContext);
		} else {
			return this.getRuleContext(i, PathSegmentContext);
		}
	}
	public SEPARATOR(): TerminalNode[];
	public SEPARATOR(i: number): TerminalNode;
	public SEPARATOR(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(AqlParser.SEPARATOR);
		} else {
			return this.getToken(AqlParser.SEPARATOR, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_path; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterPath) {
			listener.enterPath(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitPath) {
			listener.exitPath(this);
		}
	}
}


export class PathSegmentContext extends ParserRuleContext {
	public Identifier(): TerminalNode { return this.getToken(AqlParser.Identifier, 0); }
	public expression(): ExpressionContext | undefined {
		return this.tryGetRuleContext(0, ExpressionContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_pathSegment; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterPathSegment) {
			listener.enterPathSegment(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitPathSegment) {
			listener.exitPathSegment(this);
		}
	}
}


export class TermOpContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_termOp; }
	public copyFrom(ctx: TermOpContext): void {
		super.copyFrom(ctx);
	}
}
export class SingleTermContext extends TermOpContext {
	public term(): TermContext {
		return this.getRuleContext(0, TermContext);
	}
	constructor(ctx: TermOpContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterSingleTerm) {
			listener.enterSingleTerm(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitSingleTerm) {
			listener.exitSingleTerm(this);
		}
	}
}
export class MathOpContext extends TermOpContext {
	public term(): TermContext[];
	public term(i: number): TermContext;
	public term(i?: number): TermContext | TermContext[] {
		if (i === undefined) {
			return this.getRuleContexts(TermContext);
		} else {
			return this.getRuleContext(i, TermContext);
		}
	}
	public mathOperator(): MathOperatorContext {
		return this.getRuleContext(0, MathOperatorContext);
	}
	constructor(ctx: TermOpContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterMathOp) {
			listener.enterMathOp(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitMathOp) {
			listener.exitMathOp(this);
		}
	}
}


export class TermContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_term; }
	public copyFrom(ctx: TermContext): void {
		super.copyFrom(ctx);
	}
}
export class ValueTermContext extends TermContext {
	public value(): ValueContext {
		return this.getRuleContext(0, ValueContext);
	}
	constructor(ctx: TermContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterValueTerm) {
			listener.enterValueTerm(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitValueTerm) {
			listener.exitValueTerm(this);
		}
	}
}
export class AmountTermContext extends TermContext {
	public amount(): AmountContext {
		return this.getRuleContext(0, AmountContext);
	}
	constructor(ctx: TermContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterAmountTerm) {
			listener.enterAmountTerm(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitAmountTerm) {
			listener.exitAmountTerm(this);
		}
	}
}
export class ParTermContext extends TermContext {
	public termOp(): TermOpContext {
		return this.getRuleContext(0, TermOpContext);
	}
	constructor(ctx: TermContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterParTerm) {
			listener.enterParTerm(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitParTerm) {
			listener.exitParTerm(this);
		}
	}
}


export class DateTermOpContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_dateTermOp; }
	public copyFrom(ctx: DateTermOpContext): void {
		super.copyFrom(ctx);
	}
}
export class SingleDateTermContext extends DateTermOpContext {
	public dateTerm(): DateTermContext {
		return this.getRuleContext(0, DateTermContext);
	}
	constructor(ctx: DateTermOpContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterSingleDateTerm) {
			listener.enterSingleDateTerm(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitSingleDateTerm) {
			listener.exitSingleDateTerm(this);
		}
	}
}
export class DateMathOpContext extends DateTermOpContext {
	public dateTerm(): DateTermContext[];
	public dateTerm(i: number): DateTermContext;
	public dateTerm(i?: number): DateTermContext | DateTermContext[] {
		if (i === undefined) {
			return this.getRuleContexts(DateTermContext);
		} else {
			return this.getRuleContext(i, DateTermContext);
		}
	}
	public mathOperator(): MathOperatorContext {
		return this.getRuleContext(0, MathOperatorContext);
	}
	constructor(ctx: DateTermOpContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterDateMathOp) {
			listener.enterDateMathOp(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitDateMathOp) {
			listener.exitDateMathOp(this);
		}
	}
}


export class DateTermContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_dateTerm; }
	public copyFrom(ctx: DateTermContext): void {
		super.copyFrom(ctx);
	}
}
export class UnaryTermContext extends DateTermContext {
	public unaryOperator(): UnaryOperatorContext {
		return this.getRuleContext(0, UnaryOperatorContext);
	}
	constructor(ctx: DateTermContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterUnaryTerm) {
			listener.enterUnaryTerm(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitUnaryTerm) {
			listener.exitUnaryTerm(this);
		}
	}
}
export class DateAmountTermContext extends DateTermContext {
	public amount(): AmountContext {
		return this.getRuleContext(0, AmountContext);
	}
	constructor(ctx: DateTermContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterDateAmountTerm) {
			listener.enterDateAmountTerm(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitDateAmountTerm) {
			listener.exitDateAmountTerm(this);
		}
	}
}
export class DateTimeLiteralTermContext extends DateTermContext {
	public dateTimeLiteral(): DateTimeLiteralContext {
		return this.getRuleContext(0, DateTimeLiteralContext);
	}
	constructor(ctx: DateTermContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterDateTimeLiteralTerm) {
			listener.enterDateTimeLiteralTerm(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitDateTimeLiteralTerm) {
			listener.exitDateTimeLiteralTerm(this);
		}
	}
}


export class OperatorContext extends ParserRuleContext {
	public LE(): TerminalNode | undefined { return this.tryGetToken(AqlParser.LE, 0); }
	public GE(): TerminalNode | undefined { return this.tryGetToken(AqlParser.GE, 0); }
	public NE(): TerminalNode | undefined { return this.tryGetToken(AqlParser.NE, 0); }
	public LT(): TerminalNode | undefined { return this.tryGetToken(AqlParser.LT, 0); }
	public GT(): TerminalNode | undefined { return this.tryGetToken(AqlParser.GT, 0); }
	public EQ(): TerminalNode | undefined { return this.tryGetToken(AqlParser.EQ, 0); }
	public LIKE(): TerminalNode | undefined { return this.tryGetToken(AqlParser.LIKE, 0); }
	public CONTAINS(): TerminalNode | undefined { return this.tryGetToken(AqlParser.CONTAINS, 0); }
	public STARTS_WITH(): TerminalNode | undefined { return this.tryGetToken(AqlParser.STARTS_WITH, 0); }
	public ENDS_WITH(): TerminalNode | undefined { return this.tryGetToken(AqlParser.ENDS_WITH, 0); }
	public NOT_LIKE(): TerminalNode | undefined { return this.tryGetToken(AqlParser.NOT_LIKE, 0); }
	public NOT_CONTAINS(): TerminalNode | undefined { return this.tryGetToken(AqlParser.NOT_CONTAINS, 0); }
	public NOT_ENDS_WITH(): TerminalNode | undefined { return this.tryGetToken(AqlParser.NOT_ENDS_WITH, 0); }
	public NOT_STARTS_WITH(): TerminalNode | undefined { return this.tryGetToken(AqlParser.NOT_STARTS_WITH, 0); }
	public AFTER(): TerminalNode | undefined { return this.tryGetToken(AqlParser.AFTER, 0); }
	public BEFORE(): TerminalNode | undefined { return this.tryGetToken(AqlParser.BEFORE, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_operator; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterOperator) {
			listener.enterOperator(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitOperator) {
			listener.exitOperator(this);
		}
	}
}


export class UnaryOperatorContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_unaryOperator; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterUnaryOperator) {
			listener.enterUnaryOperator(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitUnaryOperator) {
			listener.exitUnaryOperator(this);
		}
	}
}


export class MathOperatorContext extends ParserRuleContext {
	public MINUS(): TerminalNode | undefined { return this.tryGetToken(AqlParser.MINUS, 0); }
	public PLUS(): TerminalNode | undefined { return this.tryGetToken(AqlParser.PLUS, 0); }
	public DIV(): TerminalNode | undefined { return this.tryGetToken(AqlParser.DIV, 0); }
	public MUL(): TerminalNode | undefined { return this.tryGetToken(AqlParser.MUL, 0); }
	public MOD(): TerminalNode | undefined { return this.tryGetToken(AqlParser.MOD, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_mathOperator; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterMathOperator) {
			listener.enterMathOperator(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitMathOperator) {
			listener.exitMathOperator(this);
		}
	}
}


export class RangeContext extends ParserRuleContext {
	public dateTermOp(): DateTermOpContext[];
	public dateTermOp(i: number): DateTermOpContext;
	public dateTermOp(i?: number): DateTermOpContext | DateTermOpContext[] {
		if (i === undefined) {
			return this.getRuleContexts(DateTermOpContext);
		} else {
			return this.getRuleContext(i, DateTermOpContext);
		}
	}
	public MUL(): TerminalNode[];
	public MUL(i: number): TerminalNode;
	public MUL(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(AqlParser.MUL);
		} else {
			return this.getToken(AqlParser.MUL, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_range; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterRange) {
			listener.enterRange(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitRange) {
			listener.exitRange(this);
		}
	}
}


export class SetContext extends ParserRuleContext {
	public value(): ValueContext[];
	public value(i: number): ValueContext;
	public value(i?: number): ValueContext | ValueContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ValueContext);
		} else {
			return this.getRuleContext(i, ValueContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_set; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterSet) {
			listener.enterSet(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitSet) {
			listener.exitSet(this);
		}
	}
}


export class IdsSetContext extends ParserRuleContext {
	public value(): ValueContext[];
	public value(i: number): ValueContext;
	public value(i?: number): ValueContext | ValueContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ValueContext);
		} else {
			return this.getRuleContext(i, ValueContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_idsSet; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterIdsSet) {
			listener.enterIdsSet(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitIdsSet) {
			listener.exitIdsSet(this);
		}
	}
}


export class AmountContext extends ParserRuleContext {
	public IntegerLiteral(): TerminalNode[];
	public IntegerLiteral(i: number): TerminalNode;
	public IntegerLiteral(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(AqlParser.IntegerLiteral);
		} else {
			return this.getToken(AqlParser.IntegerLiteral, i);
		}
	}
	public unit(): UnitContext[];
	public unit(i: number): UnitContext;
	public unit(i?: number): UnitContext | UnitContext[] {
		if (i === undefined) {
			return this.getRuleContexts(UnitContext);
		} else {
			return this.getRuleContext(i, UnitContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_amount; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterAmount) {
			listener.enterAmount(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitAmount) {
			listener.exitAmount(this);
		}
	}
}


export class TagContext extends ParserRuleContext {
	public Identifier(): TerminalNode | undefined { return this.tryGetToken(AqlParser.Identifier, 0); }
	public SingleQuotedStringLiteral(): TerminalNode | undefined { return this.tryGetToken(AqlParser.SingleQuotedStringLiteral, 0); }
	public DoubleQuotedStringLiteral(): TerminalNode | undefined { return this.tryGetToken(AqlParser.DoubleQuotedStringLiteral, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_tag; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterTag) {
			listener.enterTag(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitTag) {
			listener.exitTag(this);
		}
	}
}


export class NotTagContext extends ParserRuleContext {
	public Identifier(): TerminalNode | undefined { return this.tryGetToken(AqlParser.Identifier, 0); }
	public SingleQuotedStringLiteral(): TerminalNode | undefined { return this.tryGetToken(AqlParser.SingleQuotedStringLiteral, 0); }
	public DoubleQuotedStringLiteral(): TerminalNode | undefined { return this.tryGetToken(AqlParser.DoubleQuotedStringLiteral, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_notTag; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterNotTag) {
			listener.enterNotTag(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitNotTag) {
			listener.exitNotTag(this);
		}
	}
}


export class FilterTagContext extends ParserRuleContext {
	public Identifier(): TerminalNode | undefined { return this.tryGetToken(AqlParser.Identifier, 0); }
	public SingleQuotedStringLiteral(): TerminalNode | undefined { return this.tryGetToken(AqlParser.SingleQuotedStringLiteral, 0); }
	public DoubleQuotedStringLiteral(): TerminalNode | undefined { return this.tryGetToken(AqlParser.DoubleQuotedStringLiteral, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_filterTag; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterFilterTag) {
			listener.enterFilterTag(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitFilterTag) {
			listener.exitFilterTag(this);
		}
	}
}


export class ValueContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_value; }
	public copyFrom(ctx: ValueContext): void {
		super.copyFrom(ctx);
	}
}
export class DateTimeContext extends ValueContext {
	public dateTimeLiteral(): DateTimeLiteralContext {
		return this.getRuleContext(0, DateTimeLiteralContext);
	}
	constructor(ctx: ValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterDateTime) {
			listener.enterDateTime(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitDateTime) {
			listener.exitDateTime(this);
		}
	}
}
export class IntContext extends ValueContext {
	public IntegerLiteral(): TerminalNode { return this.getToken(AqlParser.IntegerLiteral, 0); }
	constructor(ctx: ValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterInt) {
			listener.enterInt(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitInt) {
			listener.exitInt(this);
		}
	}
}
export class FloatContext extends ValueContext {
	public FloatingPointLiteral(): TerminalNode { return this.getToken(AqlParser.FloatingPointLiteral, 0); }
	constructor(ctx: ValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterFloat) {
			listener.enterFloat(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitFloat) {
			listener.exitFloat(this);
		}
	}
}
export class StringContext extends ValueContext {
	public SingleQuotedStringLiteral(): TerminalNode | undefined { return this.tryGetToken(AqlParser.SingleQuotedStringLiteral, 0); }
	public DoubleQuotedStringLiteral(): TerminalNode | undefined { return this.tryGetToken(AqlParser.DoubleQuotedStringLiteral, 0); }
	constructor(ctx: ValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterString) {
			listener.enterString(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitString) {
			listener.exitString(this);
		}
	}
}
export class RichTextContext extends ValueContext {
	public RichTextLiteral(): TerminalNode { return this.getToken(AqlParser.RichTextLiteral, 0); }
	constructor(ctx: ValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterRichText) {
			listener.enterRichText(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitRichText) {
			listener.exitRichText(this);
		}
	}
}
export class BooleanContext extends ValueContext {
	public BooleanLiteral(): TerminalNode { return this.getToken(AqlParser.BooleanLiteral, 0); }
	constructor(ctx: ValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterBoolean) {
			listener.enterBoolean(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitBoolean) {
			listener.exitBoolean(this);
		}
	}
}
export class NullContext extends ValueContext {
	public NullLiteral(): TerminalNode { return this.getToken(AqlParser.NullLiteral, 0); }
	constructor(ctx: ValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterNull) {
			listener.enterNull(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitNull) {
			listener.exitNull(this);
		}
	}
}
export class EmptyContext extends ValueContext {
	public EmptyLiteral(): TerminalNode { return this.getToken(AqlParser.EmptyLiteral, 0); }
	constructor(ctx: ValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterEmpty) {
			listener.enterEmpty(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitEmpty) {
			listener.exitEmpty(this);
		}
	}
}
export class IdContext extends ValueContext {
	public Identifier(): TerminalNode | undefined { return this.tryGetToken(AqlParser.Identifier, 0); }
	public unaryOperator(): UnaryOperatorContext | undefined {
		return this.tryGetRuleContext(0, UnaryOperatorContext);
	}
	constructor(ctx: ValueContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterId) {
			listener.enterId(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitId) {
			listener.exitId(this);
		}
	}
}


export class UnitContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_unit; }
	public copyFrom(ctx: UnitContext): void {
		super.copyFrom(ctx);
	}
}
export class UnitPercentContext extends UnitContext {
	public MOD(): TerminalNode { return this.getToken(AqlParser.MOD, 0); }
	constructor(ctx: UnitContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterUnitPercent) {
			listener.enterUnitPercent(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitUnitPercent) {
			listener.exitUnitPercent(this);
		}
	}
}
export class UnitCustomContext extends UnitContext {
	public Identifier(): TerminalNode { return this.getToken(AqlParser.Identifier, 0); }
	constructor(ctx: UnitContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterUnitCustom) {
			listener.enterUnitCustom(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitUnitCustom) {
			listener.exitUnitCustom(this);
		}
	}
}


export class DateTimeLiteralContext extends ParserRuleContext {
	public dateLiteral(): DateLiteralContext {
		return this.getRuleContext(0, DateLiteralContext);
	}
	public timeLiteral(): TimeLiteralContext | undefined {
		return this.tryGetRuleContext(0, TimeLiteralContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_dateTimeLiteral; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterDateTimeLiteral) {
			listener.enterDateTimeLiteral(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitDateTimeLiteral) {
			listener.exitDateTimeLiteral(this);
		}
	}
}


export class DateLiteralContext extends ParserRuleContext {
	public MainDateFormat(): TerminalNode | undefined { return this.tryGetToken(AqlParser.MainDateFormat, 0); }
	public IsoDateFormat(): TerminalNode | undefined { return this.tryGetToken(AqlParser.IsoDateFormat, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_dateLiteral; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterDateLiteral) {
			listener.enterDateLiteral(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitDateLiteral) {
			listener.exitDateLiteral(this);
		}
	}
}


export class TimeLiteralContext extends ParserRuleContext {
	public Time12(): TerminalNode | undefined { return this.tryGetToken(AqlParser.Time12, 0); }
	public Time24(): TerminalNode | undefined { return this.tryGetToken(AqlParser.Time24, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AqlParser.RULE_timeLiteral; }
	// @Override
	public enterRule(listener: AqlListener): void {
		if (listener.enterTimeLiteral) {
			listener.enterTimeLiteral(this);
		}
	}
	// @Override
	public exitRule(listener: AqlListener): void {
		if (listener.exitTimeLiteral) {
			listener.exitTimeLiteral(this);
		}
	}
}


