import React from 'react';
import Main from './containers/Main';
import RootComponent from './RootComponent';

const AppEntry = () => (
  <RootComponent>
    <Main />
  </RootComponent>
);

export default AppEntry;